import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  label?: string
  title?: string
}

export const Position = memo(function Position({ image, label, title }: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Background>
        <Image {...image} />
      </Background>
    </Container>
  )
})

const Container = styled.section`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  margin-top: 12.5rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 6.875rem;
  }
`

const Label = styled.div`
  max-width: 400px;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin: 0 auto 0.375rem;

  @media (max-width: 1023px) {
    max-width: none;
    padding: 0 6.039vw;
  }
`

const Title = styled.h2`
  max-width: 400px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  margin: auto;

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 3.125rem;
    line-height: 2.875rem;
    padding: 0 6.039vw;
  }
`

const Background = styled.div`
  margin-top: 3.125rem;
  padding-bottom: 31.3%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
    padding-bottom: 96%;
  }
`
