import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  categories?: string[]
  image?: ImageProps
  index?: number
  title?: string
  URL?: string
}

export const Room = memo(function Room({
  categories,
  image,
  index,
  title,
  URL,
}: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      transform: 'scale(1)',
    },
    hidden: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
  }

  return (
    <Container
      className={`room-${index}`}
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <Anchor
        to={`${URL || ''}?rooms=${title
          ?.replace(/[^a-zA-Z]/g, '')
          .toLocaleLowerCase()}`}
      >
        {image ? <Image {...image} /> : null}
        <Wrapper dial={4} row>
          {title ? <Title className="room-title">{title}</Title> : null}
          {categories ? (
            <Categories className="room-categories" row wrap>
              {categories.map((item, index) => (
                <Category key={index}>
                  {
                    // @ts-ignore
                    item.label
                  }
                  {index < categories.length - 1 ? ',' : null}
                </Category>
              ))}
            </Categories>
          ) : null}
        </Wrapper>
      </Anchor>
    </Container>
  )
})

const Container = styled(motion.div)`
  width: calc(50% + 6.597vw);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  margin-top: 6rem;
  padding-bottom: 43%;
  position: relative;
  &.room-0 {
    width: calc(50% - 5.833vw);
    margin-top: 0;
    margin-right: 5.833vw;
    padding-bottom: 33%;
  }
  &.room-1 {
    width: calc(50% - 15.069vw);
    margin-top: -8.75rem;
    padding-bottom: 48%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1.875rem;
    padding-bottom: 100%;
    &.room-0 {
      width: 100%;
      margin-top: 3.75rem;
      margin-right: 0;
      padding-bottom: 100%;
    }
    &.room-1 {
      width: 100%;
      margin-top: 1.875rem;
      padding-bottom: 100%;
    }
  }
`

const Anchor = styled(Link)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:hover {
    &:before {
      opacity: 0.4;
    }
    &:after {
      border-width: 10px;
    }
    img {
      transform: scale(1.1);
    }
    .room-title {
      &:before {
        right: 100%;
      }
    }
    .room-categories {
      transform: translateX(-0.625rem);
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out;
    z-index: 1;
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border: 0 solid ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.4375rem;
  font-weight: 600;
  line-height: 3.125rem;
  padding: 2.5rem;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 4rem;
    padding: 1.75rem 1.875rem;
  }
`

const Categories = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  padding-right: 2.5rem;
  transition: 0.4s ease-in-out;
  transform: translateX(2.5rem);

  @media (max-width: 1023px) {
    display: none;
  }
`

const Category = styled.div`
  margin-right: 0.1875rem;
  &:last-of-type {
    margin-right: 0;
  }
`
