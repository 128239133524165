import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  list: ItemProps[]
  title?: string
}

export const Faq = memo(function Faq({
  cta,
  description,
  image,
  label,
  list,
  title,
}: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {image ? <Image className="faq-bg" {...image} /> : null}
      <Wrapper row wrap>
        <LeftSide>
          {title || label ? (
            <FadeInUp>
              <Text>
                {label ? <Label>{label}</Label> : null}
                {title ? <Title>{title}</Title> : null}
              </Text>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </LeftSide>
        <RightSide>
          <List row wrap>
            {list.map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </List>
          {cta ? (
            <FadeInUp>
              <CTA {...cta} variant="S" />
            </FadeInUp>
          ) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 10.5625rem 5.833vw 0;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% - 5.25rem);
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .faq-bg {
    width: 100%;
    height: calc(100% - 5.25rem);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding-top: 5rem;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  z-index: 2;
`

const LeftSide = styled.div`
  width: 34%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding-right: 7.639vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
  }
`

const Text = styled.div`
  padding-left: 3rem;
  position: relative;
  &:before {
    content: '';
    width: 0.75rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding-left: 2.375rem;
    &:before {
      width: 0.625rem;
    }
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
  padding-left: 3rem;

  @media (max-width: 1023px) {
    padding-left: 2.375rem;
  }
`

const RightSide = styled.div`
  width: 66%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 2.8125rem 5.833vw 4.375rem;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3rem;
    padding: 1.375rem 2.375rem 3.75rem;
  }
`

const List = styled(FlexBox)``

const CTA = styled(Button)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    display: block;
    margin-top: 3.75rem;
  }
`
